export const environment = {
	COMPANY_NAME: 'evollo',
	APP_NAME: 'ID',
	production: true,
	apiUrl: 'https://account.api.evollo.cloud',
	qualityApiUrl: 'https://quality.api.evollo.cloud',
	recaptchaId: '6Le_4tQmAAAAALwMgkZF8dyNpcwY9KG1-a8_se6m',
	apps: {
		id: 'https://apps.id.evollo.com.br/',
		admin: 'https://apps.admin.evollo.com.br',
		quality: 'https://apps.quality.evollo.com.br'
	},
	features: {
		enableSignUp: false,
		enableAzureSignUp: false,
		recaptchaEnabled: true,
		enableSupport: true,
		enableFaq: true
	}
};
